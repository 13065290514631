(function () {
  'use strict';

  class ModuleCtrl {
    constructor(Modules, Resources, Discussions, Polls, Media, $document, $stateParams, $state, $filter, $window, Utils, $rootScope, $scope) {
      let vm = this;
      vm.Modules = Modules;
      vm.Resources = Resources;
      vm.$document = $document;
      vm.Media = Media;
      vm.Polls = Polls;
      vm.$stateParams = $stateParams;
      vm.Discussions = Discussions;
      vm.$state = $state;
      vm.$filter = $filter;
      vm.Utils = Utils;
      vm.$window = $window;
      vm.$rootScope = $rootScope;
      vm.currentUser = $rootScope.user;
      vm.$scope = $scope;
      vm.isIE = $window.isIE();
      vm.$rootScope.$on('canvasTouched', function (_, arg) {
        vm.canvasValid = arg;
      });
      vm.init();
    }
    buttonsState(flag) {
      const vm = this;
      vm.canGoBack = flag;
      vm.canGoNext = flag;
    }
    init() {
      const vm = this;
      vm.prevText = 'Previous';
      vm.canvasValid = false;
      vm.nextText = '...';
      vm.buttonsState(false);
      vm.Modules.moduleView({id: vm.$stateParams.moduleId})
        .then((data)=> {
          vm.module = data.module_document;
          vm.getResourceNodes();
          vm.getResource(vm.module.current_resource_node.id);
        });
    }
    getResource(nodeId) {
      const vm = this;
      vm.buttonsState(false);
      vm.Modules.singleNodeGet({resource_node_id: nodeId, id: vm.$stateParams.moduleId})
        .then((data)=>{
          vm.resource = data.resource_node;
          vm.navigationLogic();
        });
    }
    navigationLogic() {
      const vm = this,
          {is_first, is_last, has_been_submitted} = vm.resource;
      vm.buttonsState(true);
      if (is_first === true) {
        vm.canGoBack = false;
      }
      if (has_been_submitted === true) {
        if (is_last === true) {
          vm.nextText = 'Done';
        } else {
          vm.nextText = 'Next';
        }
      } else if (is_last === true) {
        vm.nextText = 'Submit and Finish';
      } else {
        vm.nextText = 'Submit and Next';
      }
    }
    routerLogic() {
      const vm = this;
      if (vm.currentUser.role === 'Learner') {
        vm.$state.go('dashboard.index');
      } else if (vm.currentUser.role === 'Teacher') {
        vm.$state.go('dashboard.modules');
      } else {
        vm.$state.go('dashboard.learning.index');
      }
    }
    prev() {
      const vm = this;
      vm.error = false;
      if (vm.resource.previous_resource_node) {
        vm.getResource(vm.resource.previous_resource_node.id);
      }
    }
    next() {
      const vm = this;
      vm.error = false;
      if (vm.nextText === 'Done') {
        if (vm.Utils.roleCheck(['Teacher'])) {
          return vm.$state.go('dashboard.modules');
        } else if (vm.Utils.roleCheck(['admin', 'superadmin'])) {
          return vm.$state.go('dashboard.learning.index');
        }
        return vm.$state.go('dashboard.index');
      }
      if (vm.resource.next_resource_node) {
        vm.getResourceNodes();
        return vm.getResource(vm.resource.next_resource_node.id);
      }
      vm.checkDiscussions();
    }
    submitNodeHandler() {
      const vm = this;
      vm.buttonsState(false);
      vm.Modules.submitNode({resource_node_id: vm.resource.id, module_id: vm.$stateParams.moduleId})
        .then(()=>{
          if (vm.nextText === 'Submit and Finish') {
            return vm.routerLogic();
          }
          vm.init();
        })
        .catch((payload) => {
          const {data: {message}} = payload;
          vm.error = message;
          vm.buttonsState(true);
        });
    }
    submitDiscussion(discussions, counter = 0) {
      const vm = this;
      if (discussions.length !== counter) {
        const currentDiscussion = discussions[counter];
        if (currentDiscussion.discussion_type === 'canvas') {
          counter++;
          return vm.submitDiscussion(discussions, counter);
        }
        let payload = {
          id: currentDiscussion.id,
          content: currentDiscussion.reply,
          module_id: vm.$stateParams.moduleId
        };
        vm.Discussions.entriesCreate(payload)
        .then(() => {
          counter++;
          vm.submitDiscussion(discussions, counter);
        })
        .catch(() => {
          vm.checkResource();
        });
      } else {
        vm.checkResource();
      }
    }
    checkDiscussions() {
      const vm = this,
          {resource} = vm.resource;
      if (resource.discussions && resource.discussions.length > 0) {
        const replies = vm.$filter('filter')(resource.discussions, function (d) {
          return d.discussion_type !== 'canvas' && angular.isUndefined(d.reply);
        });
        if (replies.length === 0) {
          vm.buttonsState(false);
          vm.submitDiscussion(resource.discussions);
        } else {
          vm.error = 'Please fill out all discussions before submitting.';
        }
      } else {
        vm.checkResource();
      }
    }
    checkResource() {
      const vm = this,
          {resource} = vm.resource;
      switch (resource.resource_type) {
        case 'poll':
          vm.checkPolls();
          break;
        case 'canvas':
          vm.checkCanvas();
          break;
        default:
          vm.submitNodeHandler();
          break;
      }
    }
    getResourceNodes() {
      const vm = this;
      vm.Modules.resourceNodes({id: vm.module.id}).then((data)=> {
        vm.resourceNodes = data.resource_nodes;
        vm.setStepsStyles();
      });
    }
    checkCanvas() {
      const vm = this,
          {resource} = vm.resource,
          canvasDiscussion = vm.$filter('filter')(resource.discussions, function (d) {
            return d.discussion_type === 'canvas';
          })[0],
          canvas = vm.$document[0].getElementById('canvas-student');
      if (vm.canvasValid === true) {
        vm.uploadMedia(vm.Utils.dataURItoBlob(canvas.toDataURL('image/png')))
          .then((media) => {
            let payload = {
              id: canvasDiscussion.id,
              module_id: vm.$stateParams.moduleId,
              media_id: media.id
            };
            vm.Discussions.entriesCreate(payload)
            .finally(() => {
              vm.submitNodeHandler();
            });
          });
      } else {
        vm.buttonsState(true);
        vm.error = 'Please draw on the canvas before submitting.';
      }
    }
    uploadMedia(file) {
      const vm = this;
      return vm.Media.mediaCreate(file)
        .then((data) => {
          return data.media;
        });
    }
    checkPolls() {
      const vm = this,
          {resource} = vm.resource,
          poll_question_response = {
            poll_questions: []
          };
      let pollValid = false;
      angular.forEach(resource.polls, function (poll) {
        poll_question_response.id = poll.id;
        angular.forEach(poll.poll_questions, function (poll_question) {
          if (poll_question.allowed_answers_number === 1) {
            if (poll_question.answer && poll_question.answer.id) {
              pollValid = true;
              poll_question_response.poll_questions.push({
                poll_question_id: poll_question.id,
                poll_question_option_ids: [poll_question.answer.id]
              });
            } else {
              pollValid = false;
            }
          } else if (poll_question.allowed_answers_number > 1) {
            if (poll_question.answers && poll_question.answers.length === poll_question.allowed_answers_number) {
              pollValid = true;
              const answers = [];
              angular.forEach(poll_question.answers, function (answer) {
                answers.push(answer.id);
              });
              poll_question_response.poll_questions.push({
                poll_question_id: poll_question.id,
                poll_question_option_ids: answers
              });
            } else {
              pollValid = false;
            }
          }
        });
      });
      if (pollValid === true) {
        vm.submitPolls(poll_question_response);
      } else {
        vm.buttonsState(true);
        vm.error = 'Please answer all polls before submitting.';
      }
    }
    submitPolls(poll_question_response) {
      const vm = this;
      vm.Polls.pollsResponse(poll_question_response)
      .finally(() => {
        vm.submitNodeHandler();
      });
    }
    setStepsStyles() {
      const vm = this,
          stepJump = 100 / (vm.resourceNodes.length - 1);
      angular.forEach(vm.resourceNodes, function (node, index) {
        if (index === 0) {
          node.stepStyle = '';
        } else if (index === vm.resourceNodes.length - 1) {
          node.stepStyle = 'right: 0';
        } else {
          node.stepStyle = 'left: ' + (index * stepJump).toString() + '%';
        }
      });
    }
  }

  /**
   * @ngdoc object
   * @name module.controller:ModuleCtrl
   *
   * @description
   *
   */
  angular
    .module('module')
    .controller('ModuleCtrl', ModuleCtrl);
}());
